globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"761e4cf4a28e87504d0051be03b30eb9814674a2"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/hcp";
import * as Sentry from '@sentry/nextjs';

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

    initialScope: {
        tags: {
            application_name: 'HCP',
        },
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate:
        Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0.015,

    // Capture Replay for 10% of all sessions
    // Capture 100% of sessions with an error
    replaysSessionSampleRate:
        Number(process.env.NEXT_PUBLIC_REPLAYS_SESSION_SAMPLE_RATE) || 0.1,
    replaysOnErrorSampleRate:
        Number(process.env.NEXT_PUBLIC_REPLAYS_ON_ERROR_SAMPLE_RATE) || 1.0,

    // NOTE: The BrowserTracing and Replay was commented out to avoid performance issue
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error'],
        }),
    ],
});

export default Sentry;
